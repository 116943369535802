/* eslint react/prop-types: 0 */
import React from 'react';
import {graphql} from 'gatsby';
import {SEO} from '../components/seo';
import {Layout} from '../components/layout';
import {getSectionMetadata, getTagMetadata, getTimeMetadata} from '../helpers/metadataHelpers';
import PropTypes from 'prop-types';
import {useIntl} from 'gatsby-plugin-react-intl'

const Page = ({data: {markdownRemark}}) => {

    const title = {
        en: markdownRemark.frontmatter.titles.en,
        nl: markdownRemark.frontmatter.titles.nl
    };

    const description = {
        en: markdownRemark.frontmatter.descriptions.en,
        nl: markdownRemark.frontmatter.descriptions.nl
    };

    const body = {
        en: markdownRemark.fields.frontmattermd.textEN.html,
        nl: markdownRemark.fields.frontmattermd.textNL.html
    };

    const intl = useIntl();

    const html = (
        <div
            className="pageItem-content"
            dangerouslySetInnerHTML={{
                __html: body[intl.formatMessage({ id: 'lang' })]
            }}
        />
    )

    return (
      <Layout bodyClass={`singlePage`}>
          <SEO
            locales={markdownRemark.frontmatter.locales}
            lang={intl.formatMessage({ id: 'lang' })}
            title={title[intl.formatMessage({ id: 'lang' })]}
            image={markdownRemark.frontmatter.featuredImage}
            description={description[intl.formatMessage({ id: 'lang' })]}
            meta={[
                ...getTimeMetadata(markdownRemark.frontmatter.iso, markdownRemark.frontmatter.iso),
                ...getTagMetadata(markdownRemark.frontmatter.tags),
                ...getSectionMetadata(markdownRemark.frontmatter.categories)
            ]}/>
            <main className="pageItem">
                <div className="pageItem-title">
                    <h1>{title[intl.formatMessage({ id: 'lang' })]}</h1>
                </div>
                {html}
            </main>
      </Layout>
    );
}

export const query = graphql`
  query ($id: String!) {
      markdownRemark(id: {eq: $id}) {
        frontmatter {
          titles {
            en
            nl
          }
          descriptions {
            en
            nl
          }
          locales {
            en
            nl
          }
          tags
          categories
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 512) {
                src
                ...GatsbyImageSharpFluid
              }
            }
          }
          iso: date
          date(formatString: "MMMM Do, YYYY")
          excerpt {
            en
            nl
          }
        }
        html
        id
        timeToRead
        fields {
          frontmattermd {
            textEN {
              html
            }
            textNL {
              html
            }
          }
        }
      }
    }
`;

export default Page;

Page.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      id: PropTypes.string.isRequired,
      excerpt: PropTypes.shape({
        en: PropTypes.string,
        it: PropTypes.string,
      }),
      html: PropTypes.string,
      timeToRead: PropTypes.number,
      frontmatter: PropTypes.shape({
        categories: PropTypes.arrayOf(PropTypes.string),
        tags: PropTypes.arrayOf(PropTypes.string),
        daysAgo: PropTypes.number,
        iso: PropTypes.string,
        date: PropTypes.string,
        locales: PropTypes.object,
        title: PropTypes.string,
        lang: PropTypes.string,
        featuredImage: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.object
          })
        }),
        excerpt: PropTypes.string
      }),
      fields: PropTypes.shape({
         slug: PropTypes.string
      })
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteUrl: PropTypes.string.isRequired
      })
    })
  })
};
